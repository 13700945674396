<template>
  <div>
    <a-card>
      <div class="ffr">
        <a-button style="margin: 16px" @click="handleCreate" type="primary">新建</a-button>
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        @change="changePageSize"
        style="margin-bottom: 24px"
      >
        <span slot="action" slot-scope="text, record" class="blueColor">
          <a-popconfirm
              placement="bottomRight"
              title="确认删除吗？"
              @confirm="handleDelete(record)"
              okText="Yes"
              cancelText="No"
          >
          <a type="primary" >删除</a>
        </a-popconfirm>
        </span>
      </a-table>
    </a-card>

    <a-modal
      title="新建例外"
      :visible="isCreate"
      :confirmLoading="createLoading"
      destroyOnClose
      @ok="handleSaveCreate"
      @cancel="handleCancelCreate"
    >
      <a-form layout="inline" :form="createForm">
        <a-row :gutter="5" style="line-height: 3.5">

          <a-col :md="24" :sm="24">
            <a-form-item label="选择类型:">
              <a-select
                  style="width: 300px"
                  v-model="createObj.exclusiveType"
                  placeholder="请选择"
              >
                <a-select-option
                    v-for="item in exclusiveType"
                    :key="item.id"
                >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="选择城市:">
              <a-select
                  style="width: 300px"
                  v-model="createObj.cityId"
                  placeholder="请选择"
                  @change="queryFacilityList"
              >
                <a-select-option
                    v-for="item in userCities"
                    :key="item.id"
                >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="选择门店:">
              <a-select
                  v-model="createObj.facilityId"
                  placeholder="请选择"
                  style="width: 300px"
                  @change="queryKitchenList"
              >
                <a-select-option v-for="item in facilityList" :key="item.id" >
                  {{ item.optionText }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="选择档口:">
              <a-select
                  v-model="createObj.kitchenId"
                  placeholder="请选择"
                  style="width: 300px"
                  @change="queryContractList"
              >
                <a-select-option v-for="item in kitchenList" :key="item.id">
                  {{ item.code }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="选择合同:">
              <a-select
                  v-model="createObj.contractCode"
                  placeholder="请选择"
                  style="width: 300px"
                  @change="changeContract"
              >
                <a-select-option v-for="item in contractList" :key="item.id" >
                  {{ item.contractCode }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="店铺名称:">
              <a-input
                  v-model="createObj.storeName"
                  style="width: 300px"
                  placeholder="请输入"
                  disabled
              />
            </a-form-item>
          </a-col>

          <a-col :md="24" :sm="24">
            <a-form-item label="备注说明:">
              <a-textarea
                  placeholder="请输入备注"
                  class="nob"
                  v-model="createObj.remark"
              ></a-textarea>
            </a-form-item>

          </a-col>

        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
export default {
  name: "exclusionAutoDraftToFinalRentalBill",
  data() {
    return {
      pagination: defaultPagination(() => {}),
      data: [],
      columns: [
        {
          title: "类型",
          dataIndex: "exclusiveTypeName",
          key: "exclusiveTypeName",
        },
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
        },
        {
          title: "门店编号",
          dataIndex: "facilityCode",
          key: "facilityCode",
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode",
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName",
        },
        {
          title: "合同编号",
          dataIndex: "contractCode",
          key: "contractCode",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          key: "action",
          width: "200px",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      isCreate: false,
      createLoading: false,
      createObj: {},
      createForm: this.$form.createForm(this),
      facilityList: [],
      kitchenList: [],
      contractList: [],
      exclusiveType:[
        {id: 1, name: "档口"},
        {id: 2, name: "合同"}
      ],
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", (target) => {
      this.init();
    });
    this.getUserCities();
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init(this.current, this.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "bill/exclusion",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
        },
      })
        .then((res) => {
          if (res.data.returnCode == "200") {
            this.data = res.data.rows;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    handleCreate() {
      this.createObj = {};
      this.facilityList = [];
      this.kitchenList = [];
      this.contractList = [];
      this.isCreate = true;
    },
    handleCancelCreate() {
      this.isCreate = false;
    },
    handleSaveCreate() {
      this.createLoading = true;
      if (!this.createObj.exclusiveType) {
        this.$message.error("请选择排除类型！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.facilityId) {
        this.$message.error("请选择厨房！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.kitchenId) {
        this.$message.error("请选择档口！");
        this.createLoading = false;
        return;
      }
      if (this.createObj.exclusiveType === 2 && !this.createObj.contractId) {
        this.$message.error("请选择合同！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.remark) {
        this.$message.error("备注不能为空！");
        this.createLoading = false;
        return;
      }
      axios({
        url: this.$baseUrl + "bill/exclusion",
        method: "POST",
        data: { ...this.createObj, },
      }).then((res) => {
          if (res.data.returnCode == "200") {
            this.$message.info(`${res.data.returnMsg}`);
            this.init(1, 20);
            this.createLoading = false;
            this.handleCancelCreate();
          } else {
            this.createLoading = false;
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch((error) => {
          this.createLoading = false;
          this.$message.error(error.message);
        });
    },
    handleDelete(record) {
      axios({
        url:   this.$baseUrl + "bill/exclusion/" + record.id,
        method: "DELETE",
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.$message.info(`操作成功！`);
          this.init(this.current, this.pageSize);
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    queryFacilityList(val) {
      this.createLoading = true;
      axios({
        url:   this.$baseUrl + "facility/querySelectByBrandAndCity",
        method: "GET",
        params: {
          cityId: val
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.facilityList = res.data.rows;
          this.createObj.cityId = val;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.createLoading = false;
      }).catch((error) => {
        this.createLoading = false;
        this.$message.error(error.message);
      });
    },
    queryKitchenList(val) {
      this.createLoading = true;
      axios({
        url:   this.$baseUrl + "kitchen/querySelect?",
        method: "GET",
        params: {
          facilityId: val
        }
      }).then(res => {
        this.kitchenList = res.data.rows || [];
        this.createLoading = false;
      }).catch((error) => {
        this.createLoading = false;
        this.$message.error(error.message);
      });
    },
    queryContractList(val) {
      this.createLoading = true;
      axios({
        url:   this.$baseUrl + "contract/listByFacilityIdAndKitchenId",
        method: "GET",
        params: {
          facilityId: this.createObj.facilityId,
          kitchenId: val
        }
      }).then(res => {
        this.createObj.storeName = "";
        this.contractList = res.data.obj || [];
        this.createLoading = false;
      }).catch((error) => {
        this.createLoading = false;
        this.$message.error(error.message);
      });
    },
    changeContract(val) {
      const contract = (this.contractList.filter(item => item.id == val) || [
        {}
      ])[0];
      this.createObj.contractId = contract.id || null;
      this.createObj.contractCode = contract.contractCode || "";
      this.createObj.storeName = contract.storeName || "";
    },

  },
};
</script>

<style scoped>
.ffr {
  display: flex;
  justify-content: flex-end;
}
.nob {
  border-radius: 0 !important;
  width: 300px;
}
</style>
