<template>
  <div>
    <a-spin :spinning="loading">
      <a-card>
        <a-row :gutter="0" style="line-height: 3">
          <a-table
              rowKey="id"
              :columns="columns"
              :dataSource="rows"
              :pagination="pagination"
              @change="changePageSize"
          >
              <span
                  slot="progress"
                  slot-scope="text, record"
                  class="blueColor"
              >
              <template>
                <span v-if="record.status == 3">{{ record.message }}</span>
                <span v-else><a-progress :percent="record.progress" size="small"/></span>
              </template>
            </span>
            <span slot="action" slot-scope="text, record" class="blueColor">
              <a
                  v-if="record.status == 2"
                  target="_blank"
                  :href="record.fileUrl"
                  :download="record.fileUrl"
              >下载</a>
            </span>
          </a-table>
        </a-row>
      </a-card>
    </a-spin>
  </div>
</template>

<script>

import axios from "axios";
import Bus from "@/bus";
import {defaultPagination} from "@/utils/pagination";
import moment from "moment/moment";

export default {
  name: "exportFile",
  data() {
    return {
      queryParam: {},
      pagination: defaultPagination(() => {
      }),
      rows: [],
      loading: false,
      timer: null,
      columns: [
        {
          title: "导出ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "导出类型",
          dataIndex: "typeName",
          key: "typeName",
        },
        {
          title: "文件名称",
          dataIndex: "fileName",
          key: "fileName",
        },
        {
          title: "导出时间",
          dataIndex: "gmtCreated",
          key: "gmtCreated",
        },
        {
          title: "状态",
          dataIndex: "statusName",
          key: "statusName",
        },
        {
          title: "导出进度",
          dataIndex: "progress",
          key: "progress",
          scopedSlots: {customRender: "progress"}
        },
        {
          title: "操作",
          key: "action",
          width: "130px",
          scopedSlots: {customRender: "action"},
        },
      ],
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", (target) => {
      this.init();
    });
  },
  computed: {},
  destroyed(){
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url: this.$baseUrl + "export/file",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          this.rows = res.data.rows;
          const pagination = {...this.pagination};
          pagination.total = res.data.count;
          this.pagination = pagination;
          this.checkProgress();
        }
      });
    },
    checkProgress(){
      clearInterval(this.timer);
      this.timer = setInterval(()=>{
        const now = moment();
        for(const index in this.rows) {
          const expiredTime = moment(this.rows[index].gmtCreated, "YYYY-MM-DD HH:mm:ss").add(1, 'hours');
          if (this.rows[index].status === 1
              && this.rows[index].progress < 100
              && expiredTime.isAfter(now)){
            this.queryExportFile(this.rows[index].id, index);
          }
        }
      }, 5000);
    },
    queryExportFile(id, index) {
      axios({
        url: this.$baseUrl + "export/file/" + id,
        method: "GET",
      }).then(res => {
        if (res.data.obj) {
          this.$set(this.rows[index], 'progress', res.data.obj.progress);
          this.$set(this.rows[index], 'status', res.data.obj.status);
          this.$set(this.rows[index], 'statusName', res.data.obj.statusName);
          this.$set(this.rows[index], 'message', res.data.obj.message);
          this.$set(this.rows[index], 'fileUrl', res.data.obj.fileUrl);
        }
      });
    },
  },

};
</script>