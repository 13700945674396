import { requirePermission, permitAll } from "../utils/auth";
import stalls from "../assets/stalls.png";
import {FacilityActions, OrganizationActions} from "@/utils/actions";

const STATISTICS_ROUTES = {
  path: "/statistics",
  name: "Statistics",
  meta: { icon: stalls, title: "数据统计", showInMenu: true },
  component: { render: h => h("router-view") },
  children: [
    {
      path: "/statistics/dashboard",
      name: "dashboard",
      component: () => import("@/views/statistics/Dashboard"),
      meta: {
        title: "关键业务指标",
        hideBrandSelector: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          "css_china:statistics:dashboard:read"
        )
      }
    },
    {
      path: "/statistics/export",
      name: "export",
      component: () => import("@/views/statistics/Export"),
      meta: {
        title: "数据导出",
        hideBrandSelector: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          "css_china:statistics:kitchen:export"
        )
      }
    },
    {
      path: "/statistics/files",
      name: "files",
      component: () => import("@/views/statistics/ExportFile.vue"),
      meta: {
        title: "导出文件",
        hideBrandSelector: true,
        showInMenu: true,
        authorizationConfig: requirePermission(OrganizationActions.BILL_READ)
      }
    },
  ]
};

export default STATISTICS_ROUTES;
