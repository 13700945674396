<template>
  <div>
    <!-- filters -->
    <a-card>
      <a-row :gutter="0" style="line-height: 3">
        <a-form layout="inline">
          <a-row :gutter="0" style="line-height: 3.5">
            <a-col :md="7" :sm="24">
              <a-form-item label="定金编号">
                <a-input
                  v-model="queryParam[fieldsMap.downPaymentCode]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="创建人">
                <a-input
                  v-model="queryParam[fieldsMap.creator]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="使用状态">
                <a-select
                  style="width: 180px"
                  v-model="queryParam[fieldsMap.usageStatus]"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="item in usageStatus"
                    :key="item.key"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="合同编号">
                <a-input
                  v-model="queryParam[fieldsMap.contractCode]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-col>
            <!-- 按钮 -->
            <a-col :md="7" :sm="16">
              <div>
                <a-button
                  type="primary"
                  @click="search()"
                  :loading="dataLoading"
                  >查询</a-button
                >
                <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), this.search())"
                >重置</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
      <div class="btnBox">
        <div style="display: flex; align-items: center;">
          <span class="btn-item"><a-button type="primary" @click="showExportExcel">导出定金</a-button></span>
        </div>
      </div>
    </a-card>
    <!-- 表格 -->
    <div>
      <a-spin :spinning="dataLoading">
        <a-card style="margin-top: 24px">
          <a-table
            rowKey="id"
            :columns="tableColumns"
            :dataSource="data"
            :pagination="pagination"
            :scroll="{ x: 1200 }"
          >
            <span slot="contract" slot-scope="text, record">
              <ContractDetailLink
                :contractId="record[colFieldsMap.contractId]"
                :contractCode="record[colFieldsMap.contractCode]"
              />
            </span>
            <span slot="action" slot-scope="text, record">
              <div v-if="record[colFieldsMap.usageStatus] === 'UNUSED'">
                <a
                  v-if="hasApplyPermission"
                  class="blue-color"
                  @click="onOpenModal(record)"
                  >标记使用</a
                >&nbsp;
                <a-popconfirm
                  title="确认作废么？"
                  @confirm="onAbolish(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a v-if="hasAbolishPermission" class="blue-color">作废</a>
                </a-popconfirm>
              </div>
            </span>
          </a-table>
          <token-pagination
              :page="page"
              :total="total"
              :pageSize="pageSize"
              :nextToken="nextToken"
              @change="changePage"
          />
        </a-card>
      </a-spin>
    </div>
    <!-- 标记使用的modal -->
    <a-modal
      title="标记定金"
      :width="450"
      :visible="showModal"
      @ok="onSubmit"
      okText="确认使用"
      @cancel="onCancel"
      destroyOnClose
    >
      <a-form :form="form">
        <a-form-item label="选择门店">
          <a-select
            show-search
            optionFilterProp="children"
            v-decorator="[
              subFieldsMap.facilityId,
              {
                rules: [{ required: true, message: '请选择门店' }],
              },
            ]"
            placeholder="请选择"
            @select="onSelectFacility"
          >
            <a-select-option v-for="item in facilities" :key="item.id">
              {{ item.optionText }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择档口">
          <a-select
            v-decorator="[
              subFieldsMap.kitchenCode,
              {
                rules: [{ required: true, message: '请选择档口' }],
              },
            ]"
            placeholder="请选择"
            @select="onSelectKitchen"
          >
            <a-select-option v-for="item in kitchenList" :key="item.id">
              {{ item.code }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择账单">
          <a-select
            v-decorator="[
              subFieldsMap.billId,
              {
                rules: [{ required: true, message: '请选择账单' }],
              },
            ]"
            placeholder="请选择"
          >
            <a-select-option v-for="item in billList" :key="item.id">
              {{ item.code }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 导出弹窗 -->
    <a-modal title="导出表格" :visible="showExportModal" :confirmLoading="showExportModalLoading" @ok="exportExcel" @cancel="cancelExportExcel" destroyOnClose>
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>城市：</span>
          <a-select style="width: 175px;margin-left: 0.5em;" v-model="exportQuery.cityId" placeholder="请选择">
            <a-select-option v-for="item in userCitiesAll" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :md="24" :sm="24">
          <span>开始日期：</span>
          <a-date-picker format="YYYY-MM-DD" placeholder="选择开始日期" :disabledDate="disabledStartDate" @change="startDateChange" />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>结束日期：</span>
          <a-date-picker :disabledDate="disabledEndDate" format="YYYY-MM-DD" placeholder="选择结束日期" v-model="endValue" :open="endOpen" @change="endDateChange" @openChange="handleEndOpenChange" style="margin-left:8px;" />
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import Bus from "@/bus";
import { tokenOnlyPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import {GET_BILLS, GET_FACILITIES, GET_USER_CITIES, RESET_BILLS} from "@/store/modules/actions.type";
import { hasPermissions } from "@/utils/auth";
import { FacilityActions } from "@/utils/actions";
import ContractDetailLink from "@/components/link/ContractDetailLink";
import fileDownload from "js-file-download";
import TokenPagination from "@/components/Pagination/TokenPagination";
import { GET_DEPOSITS, RESET_DEPOSITS } from "@/store/modules/actions.type";

// 查询参数和后端字段的映射关系
const fieldsMap = {
  downPaymentCode: "depositCode",
  creator: "creator",
  usageStatus: "depositState",
  contractCode: "contractCode",
};

// 表格列名和后端字段的映射关系
const colFieldsMap = {
  downPaymentId: "id",
  downPaymentCode: "depositCode",
  creator: "creator",
  facilityName: "facilityName",
  kitchenCode: "kitchenCode",
  amount: "amount",
  remark: "remark",
  usageStatus: "depositState",
  billCode: "billCode",
  contractId: "contractId",
  contractCode: "contractCode",
  paidAt: "paidAt",
};

// 标记定金请求要提交的字段
const subFieldsMap = {
  facilityId: "facilityId",
  kitchenCode: "kitchenCode",
  billId: "id",
};

export default {
  name: "depositRecored",
  components: {
    TokenPagination,
    ContractDetailLink
  },
  data() {
    return {
      fieldsMap,
      colFieldsMap,
      subFieldsMap,
      page: 1,
      pageSize: 20,
      showExportModal: false,
      showExportModalLoading: false,
      endOpen: false,
      exportQuery: {},
      startValue: null,
      endValue: null,
      queryParam: {},
      dataLoading: false,
      tableColumns: [
        {
          title: "定金编号",
          width: 120,
          fixed: "left",
          dataIndex: colFieldsMap.downPaymentCode,
          key: colFieldsMap.downPaymentCode,
        },
        {
          title: "创建人",
          width: 100,
          dataIndex: colFieldsMap.creator,
          key: colFieldsMap.creator,
        },
        {
          title: "门店名称",
          width: 150,
          dataIndex: colFieldsMap.facilityName,
          key: colFieldsMap.facilityName,
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: colFieldsMap.kitchenCode,
          key: colFieldsMap.kitchenCode,
        },
        {
          title: "合同编号",
          dataIndex: "contractCode",
          width: 120,
          scopedSlots: { customRender: "contract" },
        },
        {
          title: "账单编号",
          width: 200,
          dataIndex: colFieldsMap.billCode,
          key: colFieldsMap.billCode,
        },
        {
          title: "定金金额",
          width: 120,
          align: "right",
          dataIndex: colFieldsMap.amount,
          key: colFieldsMap.amount,
        },
        {
          title: "状态",
          width: 100,
          dataIndex: colFieldsMap.usageStatus,
          key: colFieldsMap.usageStatus,
          customRender: function (text, row) {
            if (text === "USED") {
              return "已使用";
            } else if (text === "UNUSED") {
              return "未使用";
            } else if (text === "VOID") {
              return "已作废";
            }
          },
        },
        {
          title: "支付时间",
          width: 200,
          dataIndex: colFieldsMap.paidAt,
          key: colFieldsMap.paidAt,
          customRender: (text, row) => {
            if(!text.includes("1970-01-01")){
              return moment(text).format("YYYY-MM-DD HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          title: "备注",
          width: 200,
          dataIndex: colFieldsMap.remark,
          key: colFieldsMap.remark,
        },
        {
          title: "操作",
          width: 120,
          key: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      usageStatus: [
        { key: "UNUSED", name: "未使用" },
        { key: "USED", name: "已使用" },
        { key: "VOID", name: "已作废" },
      ],
      paymentList: [],
      pagination: tokenOnlyPagination(),
      showModal: false,
      modalData: {},
      form: this.$form.createForm(this),
      kitchenList: [],
      billList: [],
    };
  },
  methods: {
    ...mapActions("facilities", [GET_FACILITIES]),
    ...mapActions("cities", [GET_USER_CITIES]),
    ...mapActions("deposits", [GET_DEPOSITS, RESET_DEPOSITS]),
    fetch: async function () {
      this.dataLoading = true;
      await this.getDeposits({
        ...this.queryParam,
        rows: this.pageSize,
        page: this.page,
      });
      this.dataLoading = false;
    },
    search() {
      this.resetDeposits();
      this.page = 1;
      this.fetch();
    },
    onOpenModal(record) {
      this.modalData.depositId = record[colFieldsMap.downPaymentId];
      this.showModal = true;
    },
    onAbolish(record) {
      this.$http
        .post(`deposit/disuse?depositId=${record[colFieldsMap.downPaymentId]}`)
        .then((res) => {
          if (res.data.success) {
            this.$message.success("操作成功");
            this.search();
            this.showModal = false;
          } else {
            this.$message.error(res.data.returnMsg);
          }
        });
    },
    changePage(page) {
      this.page = page;
    },
    onSubmit() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.$http
            .post("deposit/use/confirm", {
              billId: values[subFieldsMap.billId],
              depositId: this.modalData.depositId,
            })
            .then((res) => {
              if (res.data.success) {
                this.$message.success("操作成功");
                this.search();
                this.showModal = false;
              } else {
                this.$message.error(res.data.returnMsg);
              }
            });
        }
      });
    },
    onCancel() {
      this.showModal = false;
    },
    showExportExcel() {
      this.endOpen = false;
      this.exportQuery = {};
      this.startValue = null;
      this.endValue = null;
      this.showExportModal = true;
    },
    exportExcel() {
      if (this.exportQuery.cityId === undefined ||
          this.exportQuery.cityId === null) {
        this.$message.error("请选择城市", 3);
        return;
      }
      let queryData = {
        startDate: this.startValue
            ? this.startValue.format("YYYY-MM-DD")
            : null,
        endDate: this.endValue
            ? this.endValue.format("YYYY-MM-DD")
            : null,
        cityIdList: this.getCityIdList(this.exportQuery.cityId),
      }
      this.showExportModalLoading = true;
      // this.downloadTemplateWithPost(
      //     queryData,
      //     "deposit/exportDepositList",
      //     "定金记录导出"
      // );
      this.$http.post("export/file/exportDeposits", queryData).then((res) => {
        if (res.status === 200) {
          this.$message.success("提交导出任务成功，请稍后！");
        } else {
          this.$message.error("导出失败！" + res.data);
        }
        this.$router.push({ path: "/statistics/files" });
      });
      setTimeout(() => {
        this.showExportModal = false;
        this.showExportModalLoading = false;
      }, 1000);
    },
    // download excel
    downloadTemplateWithPost(info, url, name) {
      this.$http.post(url, info,{
        responseType: "blob",
      }).then(res => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xls";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },
    cancelExportExcel() {
      this.showExportModal = false;
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue > endValue;
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return endValue.valueOf() < startValue.valueOf();
    },
    startDateChange(date, dateString) {
      this.startValue = date;
    },
    endDateChange(date, dateString) {
      this.endValue = date;
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    getCityIdList(cityId) {
      let cityIds = [];
      if (cityId === 0) {
        cityIds = this.userCities.map(e => e.id);
      }
      else {
        cityIds = [cityId];
      }
      return cityIds;
    },
    onSelectFacility(value) {
      if (value == null) return;
      this.$http
        .get("kitchen/querySelect", {
          params: {
            facilityId: value,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.kitchenList = res.data.rows;
            this.billList = [];
            this.form.setFieldsValue({
              kitchenCode: null,
              billId: null,
            });
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        });
    },
    onSelectKitchen(value) {
      if (value == null) return;
      this.$http
        .get("deposit/down/payment/list", {
          params: {
            facilityId: this.form.getFieldValue(subFieldsMap.facilityId),
            kitchenId: value,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.billList = res.data.rows;
            this.form.setFieldsValue({ billId: null });
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        });
    },
  },
  created() {
    this.getFacilities();
    this.fetch();
    Bus.$on("getTarget", (target) => {
      this.fetch();
    });
  },
  watch: {
    page: {
      handler: "fetch"
    }
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("facilities", ["facilities"]),
    ...mapGetters("cities", ["userCities", "userCitiesAll"]),
    ...mapGetters("deposits", ["total", "deposits", "nextToken"]),
    data: function() {
      return this.deposits(this.page);
    },
    hasApplyPermission() {
      return hasPermissions(
        [FacilityActions.DEPOSIT_APPLY],
        this.authenticatedUser.actions
      );
    },
    hasAbolishPermission() {
      return hasPermissions(
        [FacilityActions.DEPOSIT_ABOLISH],
        this.authenticatedUser.actions
      );
    },
  },
};
</script>
<style scoped>
.btnBox {
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-end;
}
.btn-item {
  margin-right: 16px;
}
</style>